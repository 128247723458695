import {BreedList} from '@/components/BreedList';
import {DogBreedData} from '@/types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
    AutocompleteInput,
    Create,
    SimpleForm,
    useGetList,
    useGetOne,
    useInput,
} from 'react-admin';
import {useWatch} from 'react-hook-form';

type BreedId = DogBreedData['id'];

/**
 * This component is a wrapper around a AutocompleteInput to ensure it
 * doesn't query the API when filtering, but rather performs it
 * client-side.
 *
 * Usually, we would use a ReferenceInput to acheive this, but that
 * performs filtering by re-querying the API for each character typed.
 */
const BreedInput = (props: {
    onBreedChange: (id: BreedId | undefined) => void;
}) => {
    const {onBreedChange} = props;

    const selectedBreedId = useWatch({name: 'breed_id'}) as BreedId | undefined;
    const {data: breedsData} = useGetList<DogBreedData>('breeds', {});

    useEffect(() => {
        onBreedChange(selectedBreedId);
    }, [selectedBreedId, onBreedChange]);

    const choices = useMemo(
        () =>
            (breedsData || []).map((item) => ({id: item.id, name: item.label})),
        [breedsData]
    );

    return (
        <AutocompleteInput
            source="breed_id"
            label="Choose Breed"
            choices={choices}
            TextFieldProps={{
                onChange: (event) => console.log('change', event.target.value),
            }}
        />
    );
};

const BreedSelectList = () => {
    const selectedBreedId = useWatch({name: 'breed_id'}) as BreedId | undefined;
    const {data: breedsData} = useGetList<DogBreedData>('breeds', {});
    const {data, isPending} = useGetOne('rules', {id: selectedBreedId});

    const {
        field: {
            // @ts-expect-error: useInput type is wrong
            value: breedIds,
            onChange: onBreedIdsChange,
            onBlur: onBreedIdsBlur,
        },
    } = useInput({source: 'breed_ids'});

    const handleValuesChange = useCallback(
        (values: number[]) => {
            onBreedIdsChange(values);
            onBreedIdsBlur();
        },
        [onBreedIdsBlur, onBreedIdsChange]
    );

    useEffect(() => {
        if (data?.breed_ids) {
            onBreedIdsChange(data.breed_ids);
        }
    }, [data?.breed_ids, onBreedIdsChange]);

    return (
        <BreedList
            items={breedsData}
            disabledId={selectedBreedId}
            onValuesChange={handleValuesChange}
            values={breedIds}
            disabled={typeof selectedBreedId !== 'number' || isPending}
        />
    );
};

export const RuleCreate = () => {
    const [hasSelectedBreed, setHasSelectedBreed] = useState(false);

    const onBreedChange = useCallback((id: BreedId | undefined | null) => {
        setHasSelectedBreed(id != null);
    }, []);

    return (
        <Create title="Create Breed Rule">
            <SimpleForm toolbar={hasSelectedBreed ? undefined : false}>
                <BreedInput onBreedChange={onBreedChange} />
                {!!hasSelectedBreed && <BreedSelectList />}
            </SimpleForm>
        </Create>
    );
};
