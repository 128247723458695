import {dogStatusChoices, sexChoices} from '@/lib/choices';
import {getActiveSubscriptionLabel} from '@/utils/getActiveSubscriptionLabel';
import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    List,
    ReferenceField,
    SelectInput,
    TextField,
} from 'react-admin';

const filters = [
    <SelectInput
        key="sex"
        source="sex"
        label="Sex"
        choices={sexChoices}
        alwaysOn
    />,
    <SelectInput
        key="status"
        source="status"
        label="Status"
        choices={dogStatusChoices}
        alwaysOn
    />,
];

export const DogList = () => (
    <List filters={filters} perPage={25}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" sortable={false} />
            <DateField
                source="updated_at"
                label="Updated At"
                sortable={false}
            />
            <TextField source="name" sortable={false} />
            <TextField source="sex" sortable={false} />
            <TextField
                source="microchip_number"
                label="Microchip Number"
                sortable={false}
            />
            <ReferenceField
                source="user_id"
                reference="users"
                label="Owner"
                sortable={true}
            >
                <FunctionField
                    render={(user) => `${user.first_name} ${user.last_name}`}
                />
            </ReferenceField>
            <ReferenceField
                source="user_id"
                reference="users"
                label="Location"
                sortable={false}
            >
                <FunctionField
                    render={(user) =>
                        `${user.location.suburb}, ${user.location.state}`
                    }
                />
            </ReferenceField>
            <ReferenceField
                source="user_id"
                reference="users"
                label="Registered Breeder"
                sortable={false}
            >
                <BooleanField source="is_registered_breeder" />
            </ReferenceField>
            <ReferenceField
                source="user_id"
                reference="users"
                label="Subscription"
                sortable={false}
            >
                <FunctionField
                    render={(user) =>
                        getActiveSubscriptionLabel(user.subscription)
                    }
                />
            </ReferenceField>
            <TextField source="status" sortable={false} />
        </Datagrid>
    </List>
);
