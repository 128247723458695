import {PublicAlert} from '@/components/Public/PublicAlert';
import {PublicPageLayout} from '@/components/Public/PublicPageLayout';
import {APIErrorResponse} from '@/types';
import {API_URL} from '@/utils/constants';
import {CircularProgress} from '@mui/material';
import axios, {AxiosError, AxiosResponse} from 'axios';
import Joi from 'joi';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

type VerificationData = {
    email: string;
    code: string;
};

const schema = Joi.object({
    email: Joi.string().required(),
    code: Joi.string().required(),
});

export const VerifyAccount = () => {
    const [searchParams] = useSearchParams();

    const {error: urlError, value: urlData} = useMemo(
        () =>
            schema.validate({
                email: searchParams.get('email'),
                code: searchParams.get('code'),
            }),
        [searchParams]
    );

    const hasBeganVerification = useRef(false);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);

    const errorMessage = urlError
        ? 'There is a problem with the url. Please check the link and try again.'
        : error;
    const enableVerification = !errorMessage;

    const handleVerifyAccount = useCallback(async (data: VerificationData) => {
        // Prevent this from being able to be fired twice
        if (hasBeganVerification.current) return;

        hasBeganVerification.current = true;

        setError(null);
        setIsSuccessful(false);

        try {
            setIsLoading(true);

            const response = await axios.post(
                `${API_URL}/user/verification/verify`,
                data
            );

            const success = response?.data?.success;

            if (success) {
                setIsSuccessful(true);
            } else {
                throw new Error('There was an error verifying your account');
            }
        } catch (error) {
            if (error instanceof Error) {
                let message = error.message;

                if (error instanceof AxiosError) {
                    const data = (
                        error.response as AxiosResponse<APIErrorResponse>
                    ).data;

                    message = data.message;
                }

                setError(message);
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!enableVerification) return;

        handleVerifyAccount(urlData);
    }, [urlData, enableVerification, handleVerifyAccount]);

    return (
        <PublicPageLayout title="Account Verification">
            {errorMessage && (
                <PublicAlert
                    title="Error"
                    message={errorMessage}
                    variant="error"
                />
            )}

            {!errorMessage && isLoading && (
                <CircularProgress size={24} sx={{marginTop: 3}} />
            )}

            {!errorMessage && isSuccessful && (
                <PublicAlert
                    title="Verified"
                    message={
                        'Your account is verified. Please return to the app to login.'
                    }
                    variant="success"
                />
            )}
        </PublicPageLayout>
    );
};
