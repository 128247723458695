import {DogBreedData} from '@/types';
import {Checkbox, FormControlLabel, Grid, styled} from '@mui/material';
import React, {ComponentProps, useCallback, useMemo} from 'react';

interface Props {
    items?: DogBreedData[];
    values: DogBreedData['id'][];
    disabledId?: DogBreedData['id'];
    onValuesChange: (values: DogBreedData['id'][]) => void;
    disabled?: boolean;
}

type OnChangeCallback = NonNullable<
    ComponentProps<typeof FormControlLabel>['onChange']
>;

const CHECKBOX_CLASS_NAME = 'RaCheckboxGroupInputItem-checkbox';

const ListGrid = styled(Grid)({
    paddingLeft: '1rem',
    paddingRight: '1rem',
});

const StyledFormControlLabel = styled(FormControlLabel)({
    alignItems: 'start',
    gap: '0.25rem',

    [`.MuiFormControlLabel-label`]: {
        paddingTop: '0.3rem',
    },

    [`& .${CHECKBOX_CLASS_NAME}`]: {
        width: 32,
        height: 32,
    },
});

/**
 * Groups an array of dog breeds by the 'group' field using a Map.
 * Preserves the original order of groups in the result.
 * @param dogBreeds - The array of dog breeds to group.
 * @returns A nested array where each sub-array contains breeds grouped by their 'group' value.
 */
const groupDogBreeds = (dogBreeds: DogBreedData[]) => {
    const resultMap = new Map<string, DogBreedData[]>();

    dogBreeds.forEach((breed) => {
        if (!resultMap.has(breed.group)) {
            resultMap.set(breed.group, []);
        }
        resultMap.get(breed.group)?.push(breed);
    });

    // Convert the Map back to an array format, preserving the insertion order
    const result = Array.from(resultMap, ([group, breeds]) => ({
        group,
        breeds,
    }));

    return result;
};

export const BreedList = (props: Props) => {
    const {items, disabledId, onValuesChange, values, disabled = false} = props;

    const handleItemChange = useCallback<OnChangeCallback>(
        (event, isChecked) => {
            const rawValue = event.target.value as string;
            const id = Number(rawValue);

            if (isChecked) {
                onValuesChange([...(values || []), id]);
            } else {
                onValuesChange(values.filter((v: number) => v !== id));
            }
        },
        [onValuesChange, values]
    );

    const groupedItems = useMemo(() => {
        return groupDogBreeds(items || []);
    }, [items]);

    return (groupedItems || []).map(({group, breeds}) => (
        <React.Fragment key={group}>
            <h3>{group}</h3>
            <ListGrid container columnSpacing={2} rowSpacing={0}>
                {breeds.map((item) => {
                    const checked = values ? values.includes(item.id) : false;

                    return (
                        <Grid item key={item.id} xs={3}>
                            <StyledFormControlLabel
                                htmlFor={item.id.toString()}
                                onChange={handleItemChange}
                                control={
                                    <Checkbox
                                        id={item.id.toString()}
                                        color="primary"
                                        className={CHECKBOX_CLASS_NAME}
                                        checked={checked}
                                        value={item.id.toString()}
                                        disabled={
                                            disabled || item.id === disabledId
                                        }
                                    />
                                }
                                label={item.label}
                            />
                        </Grid>
                    );
                })}
            </ListGrid>
        </React.Fragment>
    ));
};
