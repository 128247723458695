import {APIResponseCode} from '@/lib/enums';
import {ValueOf} from './helpers';

export type DogBreedData = {
    id: number;
    label: string;
    group: string;
};

export enum DogStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    INACTIVE = 'INACTIVE',
}

export enum LicenseStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED',
}

export type AustralianState =
    | 'NSW'
    | 'VIC'
    | 'SA'
    | 'ACT'
    | 'TAS'
    | 'WA'
    | 'QLD'
    | 'NT';

export type UserData = {
    id: string;
    first_name: string | null;
    last_name: string | null;
    location:
        | {
              suburb: string;
              state: AustralianState;
              latitude: number;
              longitude: number;
          }
        | {
              suburb: null;
              state: null;
              latitude: null;
              longitude: null;
          };
    company: string | null;
    is_registered_breeder: boolean;
    contact_preference: {
        email: boolean;
        sms: boolean;
        call: boolean;
    };
    phone_number: string | null;
    /**
     * Will be `null` if the user has not purchased a subscription.
     */
    subscription: {
        sku: string;
        active: boolean;
        auto_renew: boolean;
        expires_at: string;
    } | null;
};

type APIResponseCodeValue = ValueOf<typeof APIResponseCode>;

export type APIErrorResponse = {
    success: false;
    code: APIResponseCodeValue;
    message: string;
};
