const apiUrl = import.meta.env.VITE_API_URL;
const environment = import.meta.env.VITE_ENVIRONMENT;

if (!apiUrl) {
    throw new Error('VITE_API_URL environment variable is not set');
}

if (!environment) {
    throw new Error('VITE_ENVIRONMENT environment variable is not set');
}

const IS_STAGING = environment === 'staging';

export const API_URL = apiUrl;

export const PURCHASE_SKU_SUBSCRIPTION_1_DOG_IOS = IS_STAGING
    ? 'sub_male_dog_staging'
    : 'sub_male_dog';
export const PURCHASE_SKU_SUBSCRIPTION_2_4_DOGS_IOS = IS_STAGING
    ? 'sub_male_2_4_dogs_plan_staging'
    : 'sub_male_2_4_dogs_plan';
export const PURCHASE_SKU_SUBSCRIPTION_5_MORE_DOGS_IOS = IS_STAGING
    ? 'sub_male_5_more_dogs_plan_staging'
    : 'sub_male_5_more_dogs_plan';

export const PURCHASE_SKU_SUBSCRIPTION_1_DOG_ANDROID = IS_STAGING
    ? 'sub-male-dog-plan-staging'
    : 'sub-male-dog-plan';
export const PURCHASE_SKU_SUBSCRIPTION_2_4_DOGS_ANDROID = IS_STAGING
    ? 'sub-male-2-4-dogs-plan-staging'
    : 'sub-male-2-4-dogs-plan';
export const PURCHASE_SKU_SUBSCRIPTION_5_MORE_DOGS_ANDROID = IS_STAGING
    ? 'sub-male-5-more-dogs-plan-staging'
    : 'sub-male-5-more-dogs-plan';
