import {UserData} from '@/types';
import {
    PURCHASE_SKU_SUBSCRIPTION_1_DOG_ANDROID,
    PURCHASE_SKU_SUBSCRIPTION_1_DOG_IOS,
    PURCHASE_SKU_SUBSCRIPTION_2_4_DOGS_ANDROID,
    PURCHASE_SKU_SUBSCRIPTION_2_4_DOGS_IOS,
    PURCHASE_SKU_SUBSCRIPTION_5_MORE_DOGS_IOS,
    PURCHASE_SKU_SUBSCRIPTION_5_MORE_DOGS_ANDROID,
} from '@/utils/constants';

/**
 * Returns the SKU of the active subscription, or `undefined` if the user
 * doen'st have an active subscription.
 */
export const getActiveSubscriptionLabel = (
    subscription: UserData['subscription']
) => {
    if (!subscription) return 'None';

    if (subscription.active === false) return 'Expired';

    switch (subscription.sku) {
        case PURCHASE_SKU_SUBSCRIPTION_1_DOG_IOS:
        case PURCHASE_SKU_SUBSCRIPTION_1_DOG_ANDROID:
            return '1 Dog';
        case PURCHASE_SKU_SUBSCRIPTION_2_4_DOGS_IOS:
        case PURCHASE_SKU_SUBSCRIPTION_2_4_DOGS_ANDROID:
            return '2-4 Dogs';
        case PURCHASE_SKU_SUBSCRIPTION_5_MORE_DOGS_IOS:
        case PURCHASE_SKU_SUBSCRIPTION_5_MORE_DOGS_ANDROID:
            return '5+ Dogs';
        default:
            return 'Invalid SKU';
    }
};
