import {publicTheme} from '@/styles/theme';
import {Box, Container, Typography} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';

interface Props {
    title: string;
    children: ReactNode;
}

export const PublicPageLayout = (props: Props) => {
    const {title, children} = props;

    return (
        <ThemeProvider theme={createTheme(publicTheme)}>
            <Helmet>
                <title>{title} – BreedBuddies</title>
                <style>
                    {`
                        /* Regular weight */
                        @font-face {
                            font-family: 'Brockmann';
                            src: url('/fonts/brockmann-regular.woff2') format('woff2'),
                                 url('/fonts/brockmann-regular.woff') format('woff');
                            font-weight: 400;
                            font-style: normal;
                        }

                        /* Medium weight */
                        @font-face {
                            font-family: 'Brockmann';
                            src: url('/fonts/brockmann-medium.woff2') format('woff2'),
                                 url('/fonts/brockmann-medium.woff') format('woff');
                            font-weight: 500;
                            font-style: normal;
                        }

                        /* SemiBold weight */
                        @font-face {
                            font-family: 'Brockmann';
                            src: url('/fonts/brockmann-semibold.woff2') format('woff2'),
                                 url('/fonts/brockmann-semibold.woff') format('woff');
                            font-weight: 600;
                            font-style: normal;
                        }

                        /* Bold weight */
                        @font-face {
                            font-family: 'Brockmann';
                            src: url('/fonts/brockmann-bold.woff2') format('woff2'),
                                 url('/fonts/brockmann-bold.woff') format('woff');
                            font-weight: 700;
                            font-style: normal;
                        }
                    `}
                </style>
            </Helmet>

            <Container
                component="main"
                maxWidth={false}
                disableGutters
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 2,
                        maxWidth: '400px',
                        width: '100%',
                    }}
                >
                    <Box
                        component="img"
                        src="/favicon.png"
                        alt="BreedBuddies Logo"
                        sx={{
                            width: 80,
                            height: 80,
                            mb: 2,
                        }}
                    />
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{fontWeight: 600}}
                    >
                        {title}
                    </Typography>
                    {children}
                </Box>
            </Container>
        </ThemeProvider>
    );
};
