export const APIResponseCode = {
    SUCCESS: 0,
    // 100+ AUTHENTICATION
    LOGIN_INVALID: 100,
    TOKEN_INVALID: 101,
    REFRESH_TOKEN_INVALID: 102,
    // 200+ ACCOUNT HANDLING
    EMAIL_IN_USE: 200,
    EMAIL_INVALID: 201,
    PASSWORD_WEAK: 202,
    PASSWORD_MISSING: 203,
    ACCOUNT_UNVERIFIED: 210,
    VERIFICATION_INVALID: 211,
    LINK_EXPIRED: 212,
    // 300+ GENERIC API RESPONSES
    NOT_ALLOWED: 300,
    NOT_FOUND: 301,
    UNKNOWN_PROPERTY: 302,
    INVALID_PROPERTY: 303,
    ID_REQUIRED: 304,
    ID_INVALID: 305,
    // SPECIFIC API RESPONSES
    // 350+ LICENSE
    LICENSE_INVALID: 350,
    // 360+ DOG
    FILE_MISSING: 360,
    UPLOAD_ERROR: 361,
    DIMENSIONS_REQUIRED: 362,
    INVALID_CROP: 363,
    NOT_MATCHED: 364,
    // 370+ IN APP PURCHASE
    RECEIPT_REQUIRED: 370,
    RECEIPT_INVALID: 371,
    SUBSCRIPTION_ERROR: 372,
    SERVICE_UNAVAILABLE: 373,
    DUPLICATE_PURCHASE: 374,
    // 380+ NOTIFICATIONS
    UNKNOWN_PLATFORM: 380,
    NOTIFICATION_ERROR: 381,
} as const;
