import {AsideForm} from '@/components/AsideForm';
import {ExpiryDateField} from '@/components/ExpiryDateField';
import {LabeledGridItem} from '@/components/LabeledGridItem';
import {UpdateWithoutConfirmButton} from '@/components/UpdateWithoutConfirmButton';
import {LicenseStatus} from '@/types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import dayjs from 'dayjs';
import {
    Edit,
    FunctionField,
    ReferenceField,
    SimpleForm,
    TextField,
    Toolbar,
    useEditContext,
    useNotify,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import {useWatch} from 'react-hook-form';

const Title = () => {
    const {record} = useEditContext();
    if (record && 'organisation' in record)
        return `Licenses / ${record.organisation}`;
    return `Dogs`;
};

const ExpiredTag = styled('span')(({theme}) => ({
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    fontWeight: 700,
    padding: '0.25em 0.75em',
    borderRadius: '0.5em',
}));

const LicenseToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    const status = useWatch({name: 'status'});
    const record = useRecordContext();

    const expiry = record?.expiry;

    const isExpired = !expiry || dayjs(expiry).isBefore(dayjs(), 'day');
    const showApprove =
        (status === LicenseStatus.PENDING ||
            status === LicenseStatus.REJECTED) &&
        !isExpired;
    const showReject =
        (status === LicenseStatus.PENDING ||
            status === LicenseStatus.APPROVED) &&
        !isExpired;

    const mutationOptions = {
        onSuccess: () => {
            notify('ra.notification.updated', {
                type: 'info',
                messageArgs: {smart_count: 1},
                undoable: false,
            });
            redirect('/licenses');
        },
    };

    return (
        <Toolbar sx={{gap: 1}}>
            {showApprove && (
                <UpdateWithoutConfirmButton
                    label="Approve"
                    mutationMode="optimistic"
                    data={{status: LicenseStatus.APPROVED}}
                    icon={<CheckIcon />}
                    size="large"
                    variant="outlined"
                    mutationOptions={mutationOptions}
                />
            )}
            {showReject && (
                <UpdateWithoutConfirmButton
                    label="Reject"
                    mutationMode="optimistic"
                    data={{status: LicenseStatus.REJECTED}}
                    icon={<ClearIcon />}
                    size="large"
                    variant="outlined"
                    mutationOptions={mutationOptions}
                />
            )}
            {isExpired && <ExpiredTag>Expired</ExpiredTag>}
        </Toolbar>
    );
};

export const LicenseEdit = () => (
    <Grid container spacing={2}>
        <Grid item>
            <Edit title={<Title />} mutationMode="optimistic">
                <AsideForm>
                    <ReferenceField
                        source="user_id"
                        reference="users"
                        sx={{marginBottom: 2}}
                    >
                        <FunctionField
                            render={(user) =>
                                `${user.first_name} ${user.last_name}`
                            }
                            sx={{fontSize: 'h4.fontSize'}}
                        />
                    </ReferenceField>
                    <Grid container spacing={2}>
                        <LabeledGridItem xs={12}>
                            <ReferenceField
                                source="user_id"
                                reference="users"
                                label="Email"
                            >
                                <TextField
                                    source="email"
                                    emptyText="Not provided"
                                />
                            </ReferenceField>
                        </LabeledGridItem>
                        <LabeledGridItem xs={12}>
                            <ReferenceField
                                source="user_id"
                                reference="users"
                                label="Phone"
                            >
                                <TextField
                                    source="phone_number"
                                    emptyText="Not provided"
                                />
                            </ReferenceField>
                        </LabeledGridItem>
                    </Grid>
                </AsideForm>
            </Edit>
        </Grid>
        <Grid item xs>
            <Edit
                mutationMode="optimistic"
                title=" " // Prevent a second title from showing up
            >
                <SimpleForm toolbar={<LicenseToolbar />}>
                    <Grid container spacing={2}>
                        <LabeledGridItem xs={12}>
                            <TextField source="organisation" />
                        </LabeledGridItem>
                        <LabeledGridItem xs={12}>
                            <TextField source="code" />
                        </LabeledGridItem>
                        <LabeledGridItem xs={12}>
                            <TextField
                                source="postcode"
                                emptyText="Not provided"
                            />
                        </LabeledGridItem>
                        <LabeledGridItem xs={12}>
                            <ExpiryDateField source="expiry" />
                        </LabeledGridItem>
                        <LabeledGridItem xs={12}>
                            <TextField source="status" />
                        </LabeledGridItem>
                    </Grid>
                </SimpleForm>
            </Edit>
        </Grid>
    </Grid>
);
