import {ThemeOptions} from '@mui/material';
import {defaultTheme} from 'react-admin';

/**
 * MUI theme used within the Admin dashboard
 */
export const adminTheme: ThemeOptions = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        text: {
            primary: '#110E0E',
            secondary: '#110E0E99',
        },
        primary: {
            ...defaultTheme.palette?.primary,
            main: '#FD6B54',
            contrastText: '#ffffff',
        },
        secondary: {
            ...defaultTheme.palette?.secondary,
            main: '#FFFCDD',
            contrastText: '#FD6B54',
        },
        success: {
            ...defaultTheme.palette?.success,
            main: '#5CC99F',
            dark: '#24A376',
        },
        error: {
            ...defaultTheme.palette?.error,
            main: '#E11F09',
        },
    },
};

/**
 * MUI theme used on the public facing pages
 */
export const publicTheme: ThemeOptions = {
    ...adminTheme,
    palette: {
        ...adminTheme.palette,
        primary: {
            ...adminTheme.palette?.primary,
            main: '#110E0E',
        },
    },
    typography: {
        ...adminTheme.typography,
        fontFamily: 'Brockmann, sans-serif',
    },
};
