import {API} from '@/api/API';
import {APIResponseCode} from '@/lib/enums';
import {APIErrorResponse} from '@/types';
import {canBeJSONParsed} from '@/utils/canBeJSONParsed';
import {AxiosError, AxiosResponse} from 'axios';
import {NavigateFunction} from 'react-router-dom';

let api: API | null = null;

export const getApi = (navigate: NavigateFunction) => {
    if (api) return api;

    const storedAuth = localStorage.getItem('auth');

    if (!storedAuth || !canBeJSONParsed(storedAuth)) {
        navigate('/login');
        throw new Error('Need to be logged in');
    }

    const auth = JSON.parse(storedAuth);

    api = new API(auth, navigate);

    return api;
};

export const resetApi = async () => {
    if (api) {
        try {
            await api.logout();
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const code = (error.response as AxiosResponse<APIErrorResponse>)
                    .data.code;

                /**
                 * If it's a token invalid error, proceed with the
                 * logout anyway, otherwise throw.
                 */
                if (code !== APIResponseCode.TOKEN_INVALID) {
                    throw error;
                }
            }
        }
    }
    localStorage.removeItem('auth');
    api = null;
};
