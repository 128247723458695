import {DogStatus, LicenseStatus} from '@/types';

export const dogStatusChoices = [
    {id: DogStatus.PENDING, name: 'Pending'},
    {id: DogStatus.APPROVED, name: 'Approved'},
    {id: DogStatus.INACTIVE, name: 'Inactive'},
    {id: DogStatus.REJECTED, name: 'Rejected'},
];

export const licenseStatusChoices = [
    {id: LicenseStatus.PENDING, name: 'Pending'},
    {id: LicenseStatus.APPROVED, name: 'Approved'},
    {id: LicenseStatus.REJECTED, name: 'Rejected'},
];

export const sexChoices = [
    {id: 'male', name: 'Male'},
    {id: 'female', name: 'Female'},
];

export const stateChoices = [
    {id: 'NSW', name: 'NSW'},
    {id: 'VIC', name: 'VIC'},
    {id: 'SA', name: 'SA'},
    {id: 'ACT', name: 'ACT'},
    {id: 'TAS', name: 'TAS'},
    {id: 'WA', name: 'WA'},
    {id: 'QLD', name: 'QLD'},
    {id: 'NT', name: 'NT'},
];
