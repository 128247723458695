import {API_URL} from '@/utils/constants';
import {Log} from '@/utils/log';
import {removeKeys} from '@/utils/removeKeys';
import axios, {
    AxiosError,
    AxiosInstance,
    InternalAxiosRequestConfig,
} from 'axios';
import {stringify} from 'query-string';
import {GetListParams, GetListResult, Identifier} from 'react-admin';
import {NavigateFunction} from 'react-router-dom';

type AuthTokens = {
    id_token: string;
    access_token: string;
    refresh_token: string;
    user_id: string;
};

type APIPaginationResponse = {
    currentPage: number;
    nextPage: number | null;
    prevPage: number | null;
    total: number;
};

export class API {
    private client: AxiosInstance;
    private tokens: AuthTokens;
    private isRefreshing = false;
    private pendingRequests: Array<() => Promise<unknown>> = [];

    constructor(tokens: AuthTokens, navigate: NavigateFunction) {
        this.tokens = tokens;
        this.client = axios.create({
            baseURL: API_URL,
            headers: {Authorization: tokens.id_token},
        });

        this.client.interceptors.response.use(
            (response) => response,
            /**
             * Handle rejected responses
             */
            async (error: AxiosError) => {
                const response = error.response;

                if (!response) throw error;

                /**
                 * Refresh the token on any 401 response
                 */
                if (response.status === 401 && this.tokens) {
                    return this.handleUnauthorizedError(error, navigate);
                }

                Log.error(error);

                throw error;
            }
        );
    }

    /**
     * Handle refreshing the token on any unauthorized errors by queuing
     * pending requests and handling refresh logic.
     */
    private handleUnauthorizedError = async (
        error: AxiosError,
        navigate: NavigateFunction
    ) => {
        const originalRequest = error.config;

        // If already refreshing, queue the request and wait
        if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
                this.pendingRequests.push(() =>
                    this.client(originalRequest as InternalAxiosRequestConfig)
                        .then(resolve)
                        .catch(reject)
                );
            });
        }

        // Otherwise, handle refreshing of the token
        this.isRefreshing = true;

        try {
            Log.info('Refreshing token...');

            const user_id = this.tokens.user_id;

            const tokenResponse = await this.refreshToken();

            this.tokens = {...tokenResponse, user_id};
            localStorage.setItem('auth', JSON.stringify(this.tokens));

            const originalRequest = error.config;
            if (originalRequest?.headers) {
                originalRequest.headers.Authorization = this.tokens.id_token;
            }

            // Execute any pending requests with the new token
            this.pendingRequests.forEach((callback) => callback());

            // Clear pending requests after execution
            this.pendingRequests = [];

            return this.client(originalRequest as InternalAxiosRequestConfig);
        } catch (refreshError) {
            Log.error(refreshError);

            localStorage.removeItem('auth');

            navigate('/login');

            throw new Error('Unable to confirm authentication, logging out');
        } finally {
            this.isRefreshing = false;
        }
    };

    async getList(resource: string, params: GetListParams) {
        const queryParams = {
            ...params.filter,
            page: params.pagination?.page,
            page_size: params.pagination?.perPage,
        };

        /**
         * Sorting is currently only supported for 'dogs' and for the
         * 'user_id' field. So only add sort params in this instance.
         */
        if (resource === 'dogs' && params?.sort?.field === 'user_id') {
            queryParams.sort = params.sort.field;
            queryParams.sort_dir = params.sort.order.toLowerCase();
        }

        const getPageInfo = (pagination: APIPaginationResponse) => {
            const {nextPage, prevPage, total} = pagination;

            const pageInfo = {
                hasNextPage: typeof nextPage === 'number',
                hasPreviousPage: typeof prevPage === 'number',
            };

            return {total, pageInfo};
        };

        switch (resource) {
            case 'breeds': {
                const response = await this.client.get(`/dogs/breeds`);
                const breeds = response.data.data.breeds as Array<{
                    id: string;
                    label: string;
                }>;

                // Sort alphabetically by breed name
                const data = [...breeds].sort((a, b) =>
                    a.label.localeCompare(b.label)
                );

                return {data, total: data.length} as GetListResult;
            }
            case 'dogs': {
                const response = await this.client.get(
                    `/admin/dogs?${stringify(queryParams)}`
                );
                const data = response.data.data.dogs;
                return {data, ...getPageInfo(response.data.data.pagination)};
            }
            case 'licenses': {
                const response = await this.client.get(
                    `/admin/licenses?${stringify(queryParams)}`
                );
                const data = response.data.data.licenses;
                return {data, ...getPageInfo(response.data.data.pagination)};
            }
            case 'rules': {
                const response = await this.client.get(
                    `/admin/breed_rules?${stringify(queryParams)}`
                );
                const data = response.data.data.rules;
                return {data, ...getPageInfo(response.data.data.pagination)};
            }
            case 'users': {
                const response = await this.client.get(
                    `/admin/users?${stringify(queryParams)}`
                );
                const data = response.data.data.users;
                return {data, ...getPageInfo(response.data.data.pagination)};
            }
            default: {
                throw new Error(
                    `Unsupported API.getList() resource: ${resource}`
                );
            }
        }
    }

    async getOne(resource: string, id: Identifier) {
        switch (resource) {
            case 'breeds': {
                /**
                 * No API endpoint to fetch a single breed, so filtering
                 * from the whole list
                 */
                const response = await this.client.get(`/dogs/breeds`);
                const breeds = response.data.data.breeds as Array<{
                    id: string;
                    label: string;
                }>;

                return breeds.find((breed) => breed.id === id);
            }
            case 'dogs': {
                const response = await this.client.get(`/admin/dog/${id}`);
                return response.data.data.dog;
            }
            case 'licenses': {
                const response = await this.client.get(`/admin/license/${id}`);
                return response.data.data.license;
            }
            case 'rules': {
                const response = await this.client.get(
                    `/admin/breed_rules/${id}`
                );
                return {id, breed_ids: response.data.data.breed_ids};
            }
            case 'users': {
                const response = await this.client.get(`/admin/user/${id}`);
                return response.data.data.user;
            }
            default: {
                throw new Error(
                    `Unsupported API.getOne() resource: ${resource}`
                );
            }
        }
    }

    async create(resource: string, data: Record<string, unknown>) {
        switch (resource) {
            case 'rules': {
                await this.client.put(`/admin/breed_rules/${data.breed_id}`, {
                    breed_ids: data.breed_ids,
                });

                return {id: data.breed_id, breed_ids: data.breed_ids};
            }
            default: {
                throw new Error(
                    `Unsupported API.create() resource: ${resource}`
                );
            }
        }
    }

    async update(resource: string, id: string, data: Record<string, unknown>) {
        switch (resource) {
            case 'dogs': {
                /**
                 * react-admin passes through some data that we don't
                 * want to update
                 */
                const filteredData = removeKeys(data, [
                    'images',
                    'profile_image',
                    'user',
                ]);

                const response = await this.client.put(
                    `/admin/dog/${id}`,
                    filteredData
                );
                return response.data.data.dog;
            }
            case 'licenses': {
                const response = await this.client.put(
                    `/admin/license/${id}`,
                    data
                );
                return response.data.data.license;
            }
            default: {
                throw new Error(
                    `Unsupported API.update() resource: ${resource}`
                );
            }
        }
    }

    async delete(resource: string, id: Identifier) {
        switch (resource) {
            case 'rules': {
                const response = await this.client.delete(
                    `/admin/breed_rule/${id}`
                );
                return response.data.data;
            }
            default: {
                throw new Error(
                    `Unsupported API.delete() resource: ${resource}`
                );
            }
        }
    }

    async checkAuth() {
        const response = await this.client.get(`/user`);
        return !!response.data.success;
    }

    async refreshToken(): Promise<AuthTokens> {
        const response = await this.client.post(`/user/refresh`, {
            user_id: this.tokens.user_id,
            refresh_token: this.tokens.refresh_token,
        });

        return response.data.data.auth;
    }

    async logout() {
        const response = await this.client.post(
            `/user/logout`,
            {},
            {
                headers: {'Refresh-Token': this.tokens.refresh_token},
            }
        );

        return !!response.data.success;
    }
}
